import React from "react";
import styles from "./Modal.module.css";
import Modal from "@material-ui/core/Modal";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";
import { getAllEndUsers, getCompanyIds } from "../../../../containers/endUser/actions";
import { connect } from "react-redux";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { showAlert } from "../../../../containers/app/actions";
import moment from "moment";

const ModalPage = ({ open, onClose, id, ...props }) => {
    const token = localStorage.getItem("token");
    const [selectedSubscriptionId, setSelectedSubscriptionId] = React.useState("");

    const [loading, setLoading] = React.useState(false);
    const [formData, setFormData] = React.useState({
        companyId: "",
        companyName: "",
        startDate: dayjs(moment().format("YYYY-MM-DD")),
        endDate: dayjs(moment().format("YYYY-MM-DD")),
        emailEndsWith: "",
        registerationLimit: "",
        tag: "",
    });

    const [formError, setFormError] = React.useState({
        companyId: false,
        companyName: false,
        message: "",
    });

    const handleChange = (event, fieldName) => {
        if (fieldName === "endDate") {
            setFormData({
                ...formData,
                endDate: dayjs(`${event.$y}-${event.$M + 1}-${event.$D}`),
            });
            return;
        }

        if (fieldName === "startDate") {
            setFormData({
                ...formData,
                startDate: dayjs(`${event.$y}-${event.$M + 1}-${event.$D}`),
            });
            return;
        }

        if (event.target.name === "tag") {
            setFormData({
                ...formData,
                tag: event.target.value,
            });
            return;
        }
        const { value } = event.target;

        setSelectedSubscriptionId(value);
    };

    const validate = () => {
        let value = true;
        let err = {
            companyId: false,
            companyName: false,
            tag: false,
        };

        setFormError({ ...err });

        if (!formData.companyName) {
            value = false;
            err.companyName = "Enter valid Company Name";
        }
        if (!formData.companyId) {
            value = false;
            err.companyId = "Enter valid Company Id";
        }

        if (!formData.tag) {
            props.showAlert("Please select a tag");
            err.companyId = "Please select a tag";
            value = false;
        }

        if (!selectedSubscriptionId) {
            if (selectedSubscriptionId !== 0) {
                props.showAlert("Please select a subscription type");
                err.companyId = "Please select a subscription type";
                value = false;
            }
        }

        if (
            moment(`${formData?.endDate?.$M + 1}/${formData?.endDate?.$D}/${formData?.endDate?.$y}`).diff(
                moment(`${formData?.startDate?.$M + 1}/${formData?.startDate?.$D}/${formData?.startDate?.$y}`),
                "days",
            ) < 0
        ) {
            props.showAlert("End date should be either equal to or greater than start date");
            value = false;
        }

        setFormError({ ...err });
        return value;
    };

    const deleteCompanyId = () => {
        axios({
            method: "put",
            url: "/dashboard/companyId/deleteCompanyId",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                _id: id,
            },
        })
            .then((res) => {
                if (res) {
                    props.showAlert("COMPANY ID DELETED SUCCESSFULLY");
                    props.getCompanyIds(10, 1, "", "", "");
                    props.setDeleteAccount(false);
                    onClose();
                }
            })
            .catch((err) => {
                if (err && err.response) {
                    setLoading(false);
                    onClose();
                    props.showAlert("Something Went Wrong");
                }
            });
    };

    const deactivateActivateCompanyId = () => {
        axios({
            method: "put",
            url: "/dashboard/companyId/deactivateCompanyId",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                companyId: id,
            },
        })
            .then((res) => {
                if (res) {
                    props.showAlert(res?.data?.message);
                    props.getCompanyIds(10, 1, "", "", "");
                    props.setActivateDeactivateModal(false);
                    onClose();
                }
            })
            .catch((err) => {
                if (err && err.response) {
                    setLoading(false);
                    onClose();
                    props.showAlert("Something Went Wrong");
                }
            });
    };

    const addOrEditContactDetails = () => {
        axios({
            method: "post",
            url: "/dashboard/companyId/addContactDetails",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            data: {
                companyId: id,
                ...props.contactDetails,
            },
        })
            .then((res) => {
                if (res) {
                    props.showAlert(res?.data?.message);
                    props.getCompanyIds(10, 1, "", "", "");
                    onClose();
                }
            })
            .catch((err) => {
                if (err && err.response) {
                    setLoading(false);
                    onClose();
                    props.showAlert("Contact details did not update");
                }
            });
    };

    const onSubmit = () => {
        if (validate()) {
            const subscriptionObj = {
                0: "No Subscriptiom",
                1: "1-month",
                3: "3-months",
                6: "6-months",
                12: "12-months",
            };

            const subscriptionObject = {
                id: selectedSubscriptionId,
                name: subscriptionObj[selectedSubscriptionId],
            };

            setLoading(true);
            axios({
                method: "post",
                url: "/dashboard/companyId/createCompanyId",
                data: {
                    ...formData,
                    subscriptionType: subscriptionObject,
                    startDate: `${formData?.startDate?.$M + 1}/${formData?.startDate?.$D}/${formData?.startDate?.$y}`,
                    endDate: `${formData?.endDate?.$M + 1}/${formData?.endDate?.$D}/${formData?.endDate?.$y}`,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    if (res.data) {
                        setLoading(false);
                        setFormData({
                            ...formData,
                            companyId: "",
                            companyName: "",
                            emailEndsWith: "",
                            registerationLimit: "",
                            tag: "",
                        });
                        props.showAlert("COMPANY ID ADDED SUCCESSFULLY");
                        onClose();
                        props.getCompanyIds(10, 1, "", "", "");
                    }
                })
                .catch((err) => {
                    if (err && err.response) {
                        setLoading(false);
                        props.showAlert(err.response?.data?.error || "Something went wrong");
                    }
                });
        }
    };

    return (
        <Modal open={open ? true : false} onClose={onClose} className={styles.modal}>
            <div className={styles.container}>
                <div className={styles.closeButton}>
                    <IconButton onClick={onClose}>
                        <CloseRoundedIcon />
                    </IconButton>
                </div>
                {!props.deleteAccount && !props.activateDeactivateModal && !props.addContactDetailsModal && (
                    <div className={styles.content}>
                        <div className={styles.infoHeader}>
                            <h5>Add New Company Id</h5>
                        </div>

                        <div className={`${styles.flex} ${styles.justify}`}>
                            <TextField
                                variant="outlined"
                                placeholder="Company Id"
                                className={styles.halfField}
                                value={formData.companyId}
                                onChange={(e) => setFormData({ ...formData, companyId: e.target.value.toUpperCase() })}
                                error={formError.prefix}
                                helperText={formError.prefix}
                            />
                            <TextField
                                type="String"
                                variant="outlined"
                                placeholder="Company Name"
                                className={styles.halfField}
                                value={formData.companyName}
                                onChange={(e) => setFormData({ ...formData, companyName: e.target.value })}
                                error={formError.numChars}
                                helperText={formError.numChars}
                            />
                            <Box sx={{ minWidth: 185 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Subscription Type</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="subscriptionType"
                                        value={selectedSubscriptionId}
                                        label="Subscription Type"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={0}>No Subscription</MenuItem>
                                        <MenuItem value={1}>1-month</MenuItem>
                                        <MenuItem value={3}>3-months</MenuItem>
                                        <MenuItem value={6}>6-months</MenuItem>
                                        <MenuItem value={12}>12-months</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>

                            <div className={styles.halfField} />
                        </div>

                        <div className={`${styles.flex} ${styles.justify}`}>
                            <Box sx={{ minWidth: 185 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Tag</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        name="tag"
                                        value={formData.tag}
                                        label="Tag"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value="B2B">B2B</MenuItem>
                                        <MenuItem value="B2C">B2C</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={["DatePicker", "DatePicker"]}>
                                    <DatePicker
                                        label="Start Date"
                                        minDate={dayjs(moment().format("YYYY-MM-DD"))}
                                        defaultValue={dayjs(moment().format("YYYY-MM-DD"))}
                                        value={formData.startDate}
                                        onChange={(e) => handleChange(e, "startDate")}
                                    />
                                    <DatePicker
                                        label="End Date"
                                        minDate={dayjs(moment(formData.startDate).format("YYYY-MM-DD"))}
                                        defaultValue={dayjs(moment().format("YYYY-MM-DD"))}
                                        value={formData.endDate}
                                        onChange={(e) => handleChange(e, "endDate")}
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </div>

                        <div className={styles.additional}>
                            <h4>Additional conditions (optional)</h4>
                            <TextField
                                placeholder="Email ends with (format:@xyz.com)"
                                variant="outlined"
                                value={formData.emailEndsWith}
                                onChange={(e) => setFormData({ ...formData, emailEndsWith: e.target.value })}
                            />
                            <TextField
                                placeholder="Redemption limit (1, if left empty)"
                                variant="outlined"
                                value={formData.registerationLimit}
                                onChange={(e) => setFormData({ ...formData, registerationLimit: e.target.value })}
                            />
                        </div>

                        <div className={styles.Button}>
                            <button
                                disabled={loading}
                                onClick={() => {
                                    onSubmit();
                                }}
                            >
                                {loading ? "...Adding" : "Add"}
                            </button>
                        </div>
                    </div>
                )}

                {props.deleteAccount && !props.activateDeactivateModal && !props.addContactDetailsModal && (
                    <div className={styles.subscription}>
                        <h2>
                            Are you sure you want to delete <br /> this ID?
                        </h2>
                        <p>
                            By deleting this ID, users will no longer be
                            <br /> able to register with this ID.
                        </p>
                        <div className={styles.Button}>
                            <button
                                onClick={() => {
                                    deleteCompanyId();
                                }}
                            >
                                {loading ? "...Deleting" : "Yes"}
                            </button>{" "}
                            <br />
                            <br />
                            <span
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Cancel
                            </span>
                        </div>
                    </div>
                )}

                {/* Activate / Deactivate Modal */}

                {props.activateDeactivateModal && (
                    <div className={styles.subscription}>
                        <h2>
                            Are you sure you want to {props.isActiveId ? "activate" : "deactivate"} <br /> this ID ?
                        </h2>

                        {props.isActiveId ? (
                            <p>
                                By activating this ID, users can now register again
                                <br /> with this company ID and access premium features
                            </p>
                        ) : (
                            <p>
                                By deactivating this ID, users with this ID will
                                <br /> no longer access premium features
                            </p>
                        )}
                        <div className={styles.Button}>
                            <button
                                onClick={() => {
                                    deactivateActivateCompanyId();
                                }}
                            >
                                {loading ? "...Deactivating" : "Yes"}
                            </button>
                            <br />
                            <br />
                            <span
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Cancel
                            </span>
                        </div>
                    </div>
                )}

                {/* Add Contact Modal */}

                {props.addContactDetailsModal && (
                    <div className={styles.contactSection}>
                        <div className={styles.contactHeader}>
                            <h5>{`${
                                props?.contactDetails?.firstName?.length > 0 ||
                                props?.contactDetails?.emailAddress?.length > 0
                                    ? "Edit"
                                    : "Add"
                            } Contact Details`}</h5>
                        </div>
                        <TextField
                            placeholder="First Name"
                            variant="outlined"
                            value={props.contactDetails.firstName}
                            onChange={(e) => {
                                props.setContactDetails((prevContactDetails) => ({
                                    ...prevContactDetails,
                                    firstName: e.target.value,
                                }));
                            }}
                        />
                        <TextField
                            placeholder="Email Address"
                            variant="outlined"
                            value={props.contactDetails.emailAddress}
                            onChange={(e) => {
                                props.setContactDetails((prevContactDetails) => ({
                                    ...prevContactDetails,
                                    emailAddress: e.target.value,
                                }));
                            }}
                        />

                        <div className={styles.Button}>
                            <button
                                disabled={loading}
                                onClick={() => {
                                    addOrEditContactDetails();
                                }}
                            >
                                {loading ? "...Saving" : "Save"}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default connect(null, {
    getAllEndUsers,
    showAlert,
    getCompanyIds,
})(ModalPage);
